var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.shouldShowPaginate)?_c('ul',{class:[
    ,
    _vm.useStyle === 'default' ? 'default-style' : '',
    _vm.listClass,
    _vm.contentAlignClass,
    _vm.customAlign
  ]},[(_vm.showNextPrev)?_c('li',{class:[
      _vm.listItemClass,
      !_vm.hasPrevious ? (_vm.disableClassIn === 'li' ? _vm.disableClass : '') : ''
    ]},[_c('a',{class:[
        _vm.linkClass,
        !_vm.hasPrevious ? (_vm.disableClassIn === 'a' ? _vm.disableClass : '') : ''
      ],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.paginateTo(_vm.previousPage)}}},[_vm._v(_vm._s(_vm.previousText))])]):_vm._e(),_vm._l((_vm.pages),function(_page,index){return _c('li',{key:index,class:[
      _vm.listItemClass,
      _vm.activeClassIn === 'li' ? (_vm.isActive(_page) ? _vm.activeClass : '') : '',
      _vm.isDot(_page) ? _vm.disableClass : ''
    ]},[_c('a',{class:[
        _vm.linkClass,
        _vm.activeClassIn === 'a' ? (_vm.isActive(_page) ? _vm.activeClass : '') : ''
      ],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.paginateTo(_page)}}},[_vm._v(" "+_vm._s(_page)+" ")])])}),(_vm.showNextPrev)?_c('li',{class:[
      _vm.listItemClass,
      !_vm.hasNext ? (_vm.disableClassIn === 'li' ? _vm.disableClass : '') : ''
    ]},[_c('a',{class:[
        _vm.linkClass,
        !_vm.hasNext ? (_vm.disableClassIn === 'a' ? _vm.disableClass : '') : ''
      ],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.paginateTo(_vm.nextPage)}}},[_vm._v(_vm._s(_vm.nextText))])]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }